<template>
<base-dialog :show="!!error" @close="handleError" title="An error occured!">
        <p>{{error}}</p></base-dialog>
    <main class="main">
      <router-view 
        @change_status="change_status"
        @onload_page="onload_page" 
        @silent_login_success="silentLoginSuccess"
        :data_wmid="data_wmid" 
        :status="status" />
      <!-- :key="$route.fullPath"  -->
    </main>
    <div class="preloader" v-if="isLoadingPage">   
      <SvgSprite class="preloader__icon icon" symbol="logo-text" width="300" height="150"/>
      <img class="preloader__icon" src="/images/wm-loader.gif" width="200" height="200">
    </div>
</template>
<script>
// import './assets/css/styles.css';
import {loadWM} from './components/helpers/LoadWM.js';
import {setCookie, getCookie, inIframe} from './components/helpers/CookiesHelper'
import {updateOrCreateMetaTag} from "@/components/helpers/MetaTag";
export default {
  name: "App",
  data() {
    return {
      error: null,
      email: '',
      lang: 'ru',
      connection: null,
      isLoadingPage: true,
      data_wmid: '',
      status: ''
    };
  },
  methods: {
    change_status(val){
      this.status = val
    },
    silentLoginSuccess(){
      this.connect(this, 1)
    },
    onload_page(){
      this.isLoadingPage = false
    },
    logout(){
      this.$store.dispatch('logout')
    },
    async checkAccess(){
      if(this.$route.name === 'about'){
        return
      }
      try {
        await this.$store.dispatch('loadUser')
        const email = this.$store.getters['user'].Email
        if(this.email && email !== this.email)
          window.location.href = '/mail'
      } catch (err) {
        console.log("error", err.message)
      }
    },
    async firstAccess(){
      try {
        await this.$store.dispatch('loadUser')
        this.isNotLoading = false
        this.connect(this, 1)
        this.email = this.$store.getters['user'].Email
        const userAlias = this.$store.getters['user'].Info?.alias || ''
        const resp = await this.$store.dispatch('getRid')
        if(resp) {
          const em = userAlias || this.email.substr(0, 12)

          loadWM(em, this.lang, resp)
          // loadWM(em, this.lang, resp, this.$route.name === 'about')

        }
        this.status = 'success'
      } catch (err) {
        console.log('error :>> ', err.message);
        if(err && err.response && err.response.status && err.response.status === 401
          && this.$router.currentRoute.value.fullPath !== '/login')
          this.getSignOn()
        else if(!inIframe()){
          this.status = 'failed'
          this.isLoadingPage = false
        }
      }
    },
    async getSignOn(){
      try{
        const resp = await this.$store.dispatch('getSignOn')
        const regexp_template = /^[0-9]{12}$/
        if(resp && resp.wmId && regexp_template.test(resp.wmId)){
          this.data_wmid = resp.wmId
          this.status = 'load_frame'
        } else {
          this.status = 'failed'
          const isLogged = getCookie('isLogged')
          console.log('isLogged', isLogged)
          if(!isLogged){
            this.$router.push({name: 'about'})
          }
        }
        // window.location.href = '/auth/wm/login';
      } catch (err) {
        console.log('error :>> ', err);
        this.status = 'failed'
      }
    },
    connect(context, count) {
      count++
      context.connection = new WebSocket("wss://" + window.location.host + "/ws")
      context.connection.onopen = function (event) {
        count = 1
        console.log(event)
        // console.log("Websocket open connection")
        context.connection.send(JSON.stringify({message: "connection opening"}))
      }
      context.connection.onclose = function () {
        // console.log("Socket connection is closed, reconnect after 1 second")
        setTimeout(function () {
          context.connect(context)
        }, 1000)
      }
      context.connection.onerror = function () {
        // console.log("websocket error connection")
        // console.log("Timeout 5 second and reconnect")
        if (count < 10) {
          setTimeout(function () {
            context.connect(context, count)
          }, 5000)
        }
      }
      this.$store.dispatch('setConnection', context.connection )
    },
    handleError(){
      this.error = null;
    },
  },
  async created() {
    if(inIframe())
      window.top.postMessage('finish', '*')
    const newquery = Object.assign({}, this.$route.query);
    const cookies_lang = getCookie('lang')
    if (newquery['lang']) {
      this.lang = newquery['lang']
      setCookie('lang', this.lang)
    } else if(cookies_lang)
      this.lang = cookies_lang
    else if (navigator.language || navigator.userLanguage)
      this.lang = (navigator.language || navigator.userLanguage || '').substr(0, 2);
    else
      this.lang = process.env.VUE_APP_I18N_LOCALE
    try {
      await this.$router.isReady()
      const newquery = Object.assign({}, this.$route.query);
      if(newquery['lang'])
        this.lang = newquery['lang']
      setCookie('lang', this.lang)
      this.$i18n.locale = this.lang
      this.$store.dispatch('setLanguage', this.lang)
    } catch (err) {
      console.log('err :>> ', err);
      this.error = err.message || "smth went wrong"
    }
    if(this.$route.path!=='/decrypt'){
      this.firstAccess()
    // console.log("NODE_ENV", process.env.NODE_ENV)
      setInterval(()=>{
        this.checkAccess()
      }, 30000)
    }
    updateOrCreateMetaTag("og:title", 'WebMoney Mail', "meta", "property")
    updateOrCreateMetaTag("description", this.$t('description') || '', "meta", "name")
    updateOrCreateMetaTag("og:description", this.$t('description') || '', "meta", "property")
    updateOrCreateMetaTag("og:image", '/images/icons/wm-logo.svg', "meta", "property")
    updateOrCreateMetaTag("og:url", window.location.href, "meta", "property")
  }
};
</script>
<style>
@import "./App.css";
</style>
