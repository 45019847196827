import {setCookie} from './CookiesHelper'
import {URL_add_parameter} from './Useful_methods'
import router from "@/router";
import {messages} from "@/locales";
import store from "@/store";

export const loadWM = (wmid, language, rid, isLoginPage) => {
  let options = {
    rootElement: document.getElementById("webMoneyHeader"), // Контейнер для отображения (обязательный).
    serviceName: "Mail",
    recognizeMe: false,
    view: "adaptive", // Режим отображения. Возможные значения - "desktop", "mobile", "adaptive" (необязательный).
    lang: language, // Язык отображения. Возможные значения - "ru", "en", "es", "pt", "tr", "vn" (обязательный).
    wmid: wmid, // WMID пользователя (необязательный). Если пользователь не авторизован, то можно передать пустую строку "" или null.
    loginUrl: "/auth/wm/login", // Адрес сервиса для авторизации (необязательный).
    logoutUrl: "/auth/wm/logout", // Адрес сервиса для выхода (необязательный).
    reloginUrl: "/login?noredirect=true",
    rid: rid, // RID сервиса (обязательный).
    firstLevel: [
      {
        title: messages[language]['about'],
        url: '/about',
        key: 'about',
        onClick: ()=> {
          router.push({name: 'about'})
        }
      },
      {
        title: messages[language]['mail_service'],
        url: '/',
        key: 'mail',
        onClick: ()=> {
          const path = store.getters['user'].Info ? "/" : "/login"

          window.location.href = path

          // window.location.href = '/'
        }
      },
      {
        title: messages[language]['files'],
        url: "https://files.web.money/",
        onClick: () => {
          window.open("https://files.web.money/", '_blank')
        }
      },
      {
        title: messages[language]['memo'],
        url: "https://memo.web.money/",
        onClick: () => {
          window.open("https://memo.web.money/", '_blank')
        }
      },
      {
        title: messages[language]['notes'],
        url: "https://notes.web.money/",
        onClick: () => {
          window.open("https://notes.web.money/", '_blank')
        }
      },
      {
        title: messages[language]['video'],
        url: "https://video.web.money/",
        onClick: () => {
          window.open("https://video.web.money/", '_blank')
        }
      }
    ],
    noIndent: true,
  }
  if (wmid.split('@').length > 1) {
    options['customUser'] = {nick: wmid, avatarUrl: "/images/icons/contacts-fill.svg"}
  }

  typeof window.WebMoneyHeader != "undefined" ? new window.WebMoneyHeader().init(options) : console.error("WebMoneyHeader undefined");

  if (isLoginPage) {
    typeof window.WebMoneyFooter != "undefined" ? new window.WebMoneyFooter().init({
      rootElement: document.getElementById("webMoneyFooter"), // Контейнер для отображения (обязательный).
      view: "adaptive", // Режим отображения. Возможные значения - "desktop", "mobile", "adaptive" (необязательный).
      lang: language, // Язык отображения. Возможные значения - "ru", "en", "es", "pt", "tr", "vn" (обязательный).
      supportedLangs: ["ru", "en"], // Языки для выбора (необязательный). Если язык один, то выбор языка скрыт.
      illiciumPlaceId: "59", // Уникальный идентификатор рекламного места (необязательный).
      illiciumView: "top", // Режим отображения рекламного места. Возможные значения - "top", "bottom" (необязательный).
      noIndent: true,
      onChangeLang: function (lang) { // Колбэк-функция при выборе языка (необязательный).
        setCookie('lang', lang)
        window.location.href = URL_add_parameter(window.location.href, 'lang', lang);
      }
    }) : console.error("WebMoneyFooter undefined");
  }
}

export const loadWMFooter = (language) => {
  typeof window.WebMoneyFooter != "undefined" ? new window.WebMoneyFooter().init({
    rootElement: document.getElementById("webMoneyFooter"), // Контейнер для отображения (обязательный).
    view: "adaptive", // Режим отображения. Возможные значения - "desktop", "mobile", "adaptive" (необязательный).
    lang: language, // Язык отображения. Возможные значения - "ru", "en", "es", "pt", "tr", "vn" (обязательный).
    supportedLangs: ["ru", "en"], // Языки для выбора (необязательный). Если язык один, то выбор языка скрыт.
    illiciumPlaceId: "59", // Уникальный идентификатор рекламного места (необязательный).
    illiciumView: "top", // Режим отображения рекламного места. Возможные значения - "top", "bottom" (необязательный).
    noIndent: true,
    onChangeLang: function (lang) { // Колбэк-функция при выборе языка (необязательный).
      setCookie('lang', lang)
      window.location.href = URL_add_parameter(window.location.href, 'lang', lang);
    }
  }) : console.error("WebMoneyFooter undefined");
}

