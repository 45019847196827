<template>
  <li class="top-panel__item top-panel__item--checkbox-group">
      <div class="checkbox-group btn-group">
        <div class="checkbox-group__checkbox">
          <!-- <input class="checkbox-group__input" type="checkbox" v-model="allLetters" @change="check" id="top-panel-checkbox"> -->
          <label class="checkbox-group__label" :class="{'checkbox-group__label__checked': isCheckedALL}" @click="check" data-bs-toggle="tooltip" for="top-panel-checkbox" :title="$t('select_mails')"></label>
        </div>
        <div class="dropdown dropdown--element">
          <button class="dropdown__button dropdown__button--element" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <SvgSprite class="dropdown__icon icon" symbol="arrow-dropdown" size="32"/>
          </button>
          <ul class="dropdown__menu dropdown-menu">
            <li class="dropdown__item" @click="selectMails('all')">
              <span class="dropdown__name">{{$t('all')}}</span>
            </li>
            <li class="dropdown__item" @click="selectMails('read')">
              <span class="dropdown__name">{{$t('read')}}</span>
            </li>
            <li class="dropdown__item" @click="selectMails('unread')">
              <span class="dropdown__name">{{$t('unread')}}</span>
            </li>
            <li class="dropdown__item" @click="selectMails('marked')">
              <span class="dropdown__name">{{$t('marked')}}</span>
            </li>
            <li class="dropdown__item" @click="selectMails('unmarked')">
              <span class="dropdown__name">{{$t('unmarked')}}</span>
            </li>
          </ul>
        </div>
      </div>
  </li>
  <li class="top-panel__item" :title="$t('refresh')" data-bs-toggle="tooltip">
    <button class="top-panel__button top-panel__button--element" @click="refresh">
      <SvgSprite symbol="refresh" size="32" :class="{rotateButton: isLoading}"/>

      <span class="top-panel__name">{{$t('refresh')}}</span>
    </button>
  </li>
  <li class="top-panel__item" :title="$t('remove_all')" v-if="!hasChecked && isTrash" >       
    <button class="top-panel__button" @click="removeAll">
      <SvgSprite class="icon" symbol="trash-all" size="32"/>
      <span class="top-panel__name">{{$t('delete')}}</span>
    </button>
  </li>
  <li class="top-panel__item top-panel__item--search" data-bs-toggle="tooltip"
    :class="{'top-panel__item--active': isActive}">
    <search-line 
      v-if="!isMobile && this.subfolders && this.subfolders.length > 0"
      :lang="lang"
      :hasChecked="hasChecked"
      :email="email"
      wrapper=".top-panel"
      :amount="amount"
      :subfolders="subfolders"
      :activeEmail="activeEmail"
      :counter_click_folder="counter_click_folder"
      @active_search="active_search"
      @search_mail="search_mail"
      @refresh="refresh">
    </search-line>
    <label class="top-panel__name" for="search-input">{{$t('search_mails')}}</label>
  </li>
  <li class="top-panel__item top-panel__item--actions top-panel__item--active" v-if="hasChecked"
      :class="{'top-panel__item--active': isActive }">
    <ul class="top-panel__list">
      <li v-if="!userAlias" class="top-panel__item" data-bs-toggle="tooltip" :title="$t('add_to_folder')" >
        <div class="dropdown dropdown--element">
          <button class="dropdown__button dropdown__button--element" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <SvgSprite class="dropdown__icon icon" symbol="folder" size="32"/>
            <span class="dropdown__name">{{$t('add_to_folder')}}</span>
          </button>
        <ul class="dropdown__menu dropdown-menu">
          <li class="dropdown__item" v-for="subf in subfolders.filter(el => !el.isCollector && !el.isSubCollector)" :key="subf.name" @click="move_to(subf.id)"><span class="dropdown__name">{{getFolderName(subf)}}</span></li>
        </ul>
        </div>
      </li>
      <li class="top-panel__item" data-bs-toggle="tooltip" :title="$t('add_label')" v-if="!isCheckedAllFolder && !userAlias">
        <div class="dropdown dropdown--element">
          <button class="dropdown__button dropdown__button--element" type="button" 
            data-bs-toggle="dropdown" aria-expanded="false">
            <SvgSprite class="dropdown__icon icon" symbol="label" size="32"/>
            <span class="dropdown__name">{{$t('add_label')}}</span>
          </button>
          <ul class="dropdown__menu dropdown-menu">
            <li class="dropdown__item" @click="add_label">{{$t('create_label')}}</li>
            <li class="dropdown__item" v-for="label in labelsFilter" :key="label.id"
              @click="set_label(label.id)">
              <SvgSprite class="dropdown__icon icon icon-color-white" symbol="label-mask" size="32" :style="labelColor(label.color)"/>
              <span class="dropdown__name">{{label.name}}</span>
            </li>
          </ul>
        </div>
      </li>
      <li class="top-panel__item" data-bs-toggle="tooltip" :title="$t('delete')" @click="deleteMail">
        <button class="top-panel__button top-panel__button--element">
          <SvgSprite class="icon" symbol="trash" size="32"/>
          <span class="top-panel__name">{{$t('delete')}}</span>
        </button>
      </li>
      <li class="top-panel__item" data-bs-toggle="tooltip" >
        <div class="dropdown dropdown--element">
          <button class="dropdown__button dropdown__button--element pe-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <SvgSprite class="dropdown__icon icon" symbol="dots" size="32"/>
          </button>
          <ul class="dropdown__menu dropdown-menu">
            <li class="dropdown__item" @click="mark_as_read('seen')"><span class="dropdown__name">{{$t('mark_as_read')}}</span></li>
            <li class="dropdown__item" @click="mark_as_read('unseen')"><span class="dropdown__name">{{$t('mark_as_unread')}}</span></li>
            <li class="dropdown__item" @click="mark_as_read('flagget')"><span class="dropdown__name">{{$t('mark_as_important')}}</span></li>
            <li class="dropdown__item" @click="mark_as_read('unflagget')"><span class="dropdown__name">{{$t('mark_as_unimportant')}}</span></li>
            <li class="dropdown__item" @click="move_to(getInboxId)" v-if="isSpam && getInboxId !== -1"><span class="dropdown__name">{{$t('remove_from_spam')}}</span></li>
            <li class="dropdown__item" @click="move_to(getJunkId)" v-else><span class="dropdown__name">{{$t('add_to_spam')}}</span></li>            
          </ul>
        </div>
      </li>
    </ul>
  </li>
</template>
<script>
import SearchLine from './SearchLine.vue'
export default {
  name: "MailsList",
  components: {
    SearchLine
  },
  emits: ['delete_mail', 'search_mail', 'refresh', 'mark_as_read', 'move_to', 'select_mails', 'remove_all', 'add_label'],
  props: {
    email: String,
    hasChecked: Boolean,
    subfolders: Array,
    isLoading: Boolean,
    amount: Number,
    labels: Array,
    isCheckedALL: Boolean,
    activeEmail: Number,
    counter_click_folder: Number,
    lang: String,
    isCheckedAllFolder: Boolean,
    isMobile: Boolean
  },
  data(){
    return {
      searchLineObject: {},
      advancedSearchOtherActive: false,
      choosen_email: 'All',
      folder: '',
      folderError: false,
      flags: ['trash', "sent", "junk", "draft"],
      accountAliases: [],
      date_from: null,
      date_to: null,
      dp1: null,
      dp2: null,
      isActive: false,
      userAlias: ''
    }
  },
  computed: {
    labelsFilter(){
      if(this.labels){
        const arr = this.labels.filter(el=>el.folder_id === this.activeEmail)
        return arr
      }
      return []
    },
    getJunkId(){
      const i = this.subfolders.findIndex(el => el.name === 'INBOX.Junk')
      if(i !== -1)
        return this.subfolders[i].id
      return -1
    },
    isTrash(){
      if(this.activeEmail && this.activeEmail >= 0 && this.subfolders){
        const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
        if(j !== -1)
          if(this.subfolders[j].name === 'INBOX.Trash' || this.subfolders[j].name === 'INBOX.Junk')
            return true
      }
      return false
    },
    isSpamOrSent(){
      if(this.activeEmail && this.activeEmail >= 0 && this.subfolders){
        const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
        if(j !== -1)
          if(this.subfolders[j].name === 'INBOX.Sent' || this.subfolders[j].name === 'INBOX.Junk' || this.subfolders[j].name === 'INBOX.Drafts')
            return true
      }
      return false
    },
    isSpam(){
      if(this.activeEmail >= 0 && this.subfolders?.length > 0){
        const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
        if(j !== -1 && this.subfolders[j].system === "junk")
          return true
      }
      return false
    },
    getInboxId(){
      if(this.subfolders?.length > 0){
        const j = this.subfolders.findIndex(el => el.name === "INBOX")
        if(j!==-1)
          return this.subfolders[j].id
      }
      return -1
    }
  },
  methods: {
    active_search(isActive){
      this.isActive = isActive
    },
    search_mail(val, type){
      this.$emit('search_mail', val, type)
    },
    add_label(){
      this.$emit('add_label')
    },
    set_label(label_id){
      this.$emit('mark_as_read', 'labeled', label_id)
    },
    getFolderName(folder){
      if(folder.name === "INBOX")
        return this.$t(folder.name)
      if(folder.system){
        const i = this.flags.findIndex(el => folder.system === el)
        if(i !== -1)
          return this.$t(this.flags[i])
      }
      const arr = folder.name.split('.') 
      if(arr.length > 0)
        return arr[arr.length-1]
      return folder.name
    },
    labelColor(color){
      return { 'background-color': color }
    },
    check(){
      const flag = this.isCheckedALL ? 'clear' : 'all_on_the_page'
      this.$emit('select_mails', flag)
    }, 
    selectMails(flag){
      this.$emit('select_mails', flag)
    },
    move_to(name){
      this.$emit('move_to', name)
    },
    mark_as_read(flag){
      this.$emit('mark_as_read', flag)
    },
    refresh(){
      this.$emit('refresh')
    },
    choose(em){
      this.choosen_email = em
    },
    deleteMail(){
      this.$emit("delete_mail")
    },
    removeAll(){
      this.selectMails('all')
      this.$emit("delete_mail")
    },
  },
  created() {
    this.userAlias = this.$store.getters['user'].Info?.alias
  }
}
</script>
<style scoped>
.rotateButton{
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
    -webkit-transform: rotate(360deg); 
    transform:rotate(360deg); 
  } 
}
.checkbox-group__label__checked {
    background-color: #0080D8;
}
.checkbox-group__label__checked:before {
    background: url("/images/icons/check-white.svg") no-repeat 50%;
    background-size: contain;
}
.checkbox_active{
  color: #FFFFFF;
  background-color: #0080D8;
}

.top-panel__item:not(.top-panel__item--search):not(.top-panel__item--actions) {
  padding: 0;
}

.top-panel__button,
.top-panel__item--actions .dropdown__button--element {
  padding: 4px;
}

.top-panel__item--search {
  height: 40px;
}

.dropdown__button .dropdown__name {
  display: none;
}

@media (min-width: 1567px) {
    .top-panel__item--search {
      width: 86px;
    }

    .top-panel__item--search .top-panel__name {
      cursor: pointer;
      padding: 10px 0;
    }

    .top-panel__item--actions .dropdown__button--element,
    .top-panel__button--element {
      padding: 4px 8px 4px 4px;
    }

    .dropdown__button .dropdown__name {
      display: block;
    }
}


</style>
