<template>
  <section class="aside-panel"
    :class="{'aside-panel--collapsed': isButtonCollapse}">
    <button class="aside-panel__button aside-panel__button--collapse" @click="isButtonCollapse = !isButtonCollapse">
      <SvgSprite class="icon" symbol="collapse" size="20"/>
    </button>
    <button class="aside-panel__button aside-panel__button--open"
      @click="isHidedMenu = !isHidedMenu; isButtonCollapse ? isButtonCollapse = false : ''"
      ref="aside_panel_toggle_button"
      id="nav-icon6" href="#" role="button" data-bs-toggle="offcanvas" data-bs-target="#aside-panel-offcanvas">
      <span class="aside-panel__icon"></span>
    </button>
    <div class="aside-panel__offcanvas"
      data-bs-backdrop="false"
      :class="{'offcanvas offcanvas-start': isAsidePanelOffCanvas, 'visibility_aside_panel': isAsidePanelOffCanvas, 'visibility_aside_panel_unset': !isAsidePanelOffCanvas}"
      ref="aside_panel"
      tabindex="-1" id="aside-panel-offcanvas">
        <button class="aside-panel__button aside-panel__button--hide" type="button"
          @click="isHidedMenu = false"
          data-bs-dismiss="offcanvas">
            
          <span class="aside-panel__text">
            <SvgSprite class="icon" symbol="close" size="30"/>
            Скрыть
          </span>
          <SvgSprite class="icon" symbol="arrow-left" size="30"/>
        </button>

        
        <button class="button button--primary button--message" type="button" @click="create_mail()">
          <span class="button__text">
            <SvgSprite class="icon" symbol="edit" size="30"/>
            {{$t("new_msg")}}
          </span>
        </button>
        <button v-if="!account?.settings?.slim_list_aliases && !isButtonCollapse && !userAlias"
          class="button button--simple" type="button"
          :class="{'button--active': search_alias === aliasesEmails[0]}"
          @click="setNewSearchAlias(aliasesId[0])">
          <span class="button__text">{{ aliasesEmails[0] }}</span>
        </button>
        <ul id="aliases_list" class="aside-panel__mailboxes sdfsdfsdfdsf" v-if="!account?.settings?.slim_list_aliases && !isButtonCollapse && !userAlias">
          <li class="aside-panel__item" v-for="(email, i) in aliasesEmails.slice(1)" :key="email"
            :id="email"
            @click="setNewSearchAlias(aliasesId[i+1])"
            :class="{'aside-panel__item--active': search_alias === email}">
              <SvgSprite class="aside-panel__icon icon" symbol="line" size="30"/>
              <span class="aside-panel__text">{{ email }}</span>
              <!-- <span class="aside-panel__counter">+12 </span> -->
          </li>
        </ul>
        <vue-select v-else
          :select_values="aliasesEmails"
          :_values="aliasesId"
          :actual_value="search_alias"
          :disabled="userAlias"
          @change_value="setNewSearchAlias"
        ></vue-select>
      <div class="navigation" v-if="foldersLoaded">
        <div class="navigation__item">
          <button class="navigation__button collapsed dropzone1"
            :class="active===folders[0].id ? 'navigation__button--active' : ''"
            @click="setActive(folders[0].name, folders[0].id, getFolderName(folders[0]), folders[0].unread, true)"
            @drop="onDrop($event, folders[0].id)"
            @dragover.prevent
            data-bs-toggle="collapse" :data-bs-target="'#navigation-inner-'+folders[0].name.toLowerCase()" aria-expanded="false">
            <span class="navigation__icon">
              <SvgSprite class="icon" symbol="message" size="30"/>
            </span>
            <span class="navigation__name">{{getFolderName(folders[0])}}</span>
            <span class="navigation__counter" v-if="folders[0].unread > 0">+{{folders[0].unread}}</span>
          </button>
          <div class="inner" v-if="inboxInboxSubfolders.length > 0">
            <ul class="inner__list">
              <folder-content v-for="subf in inboxInboxSubfolders" :key="subf.name"
              :folder="subf"
              :active="active"
              @open_folder="setActive"
              @move_to="moveTo"></folder-content>
            </ul>
          </div>
        </div>
        <div class="navigation__item" v-for="folder in otherSubfolders" :key="folder.name">
          <button class="navigation__button collapsed dropzone1 "
            @drop="onDrop($event, folder.id)"
            @dragover.prevent
            :class="active===folder.id ? 'navigation__button--active' : ''"
            @click="setActive(folder.name, folder.id, getFolderName(folder), folder.unread, true)"
            data-bs-toggle="collapse"
            :data-bs-target="'#navigation-inner-'+getFolderName(folder).toLowerCase()"
            aria-expanded="false">
            <SvgSprite class="navigation__icon icon" :symbol="getFolderImg(folder)" size="30"/>
            <span class="navigation__name">{{getFolderName(folder)}}</span>
            <span class="navigation__counter" v-if="folder.unread > 0 && folder.system !== 'junk'">+{{folder.unread}}</span>
          </button>
          <div class="inner" v-if="folder.subfolders.length > 0">
            <ul class="inner__list">
              <folder-content v-for="subf in folder.subfolders" :key="subf.name"
                :folder="subf"
                :active="active"
                @open_folder="setActive"
                @move_to="moveTo"></folder-content>
            </ul>
          </div>
        </div>
        <div class="navigation__item" v-for="folder in collectorFolders" :key="folder.name">
          <button class="navigation__button collapsed dropzone1 "
                  :class="active===folder.id ? 'navigation__button--active' : ''"
                  @click="setActive(folder.name, folder.id, folder.name, folder.unread, true)"
                  aria-expanded="false">
            <SvgSprite class="navigation__icon icon" symbol="archive-mail" size="30"/>
            <span class="navigation__name">{{folder.name}}</span>
            <span class="navigation__counter" v-if="folder.unread > 0">+{{folder.unread}}</span>
          </button>
          <div class="inner" v-if="folder.subfolders.length > 0">
            <ul class="inner__list">
              <folder-content v-for="subf in folder.subfolders" :key="subf.name"
                              :folder="subf"
                              :active="active"
                              @open_folder="setActive"
                              @move_to="moveTo"></folder-content>
            </ul>
          </div>
        </div>
        <button v-if="!userAlias" class="button button--secondary" type="button" @click='createFolder'>
          <span class="button__text">
            <SvgSprite class="icon" symbol="folder-add" size="30"/>
            {{$t('add_folder')}}
          </span>
        </button>
        <div class="navigation__item" v-if="!userAlias">
          <button class="navigation__button"
            :class="active==='labels' ? 'navigation__button--active' : ''"
            type="button" data-bs-toggle="collapse" data-bs-target="#navigation-inner-labels" aria-expanded="false">
              <span class="navigation__icon">
                <SvgSprite class="icon" symbol="label" size="30"/>
              </span>
              <span class="navigation__name">{{$t('labels')}}</span>
          </button>
          <SvgSprite class="navigation__add icon" symbol="plus" size="30" @click.prevent.stop="add_label" id="c" :title="$t('create_label')"/>
          <div class="inner">
            <ul class="inner__list">
              <li class="inner__item inner__item--label" v-for="label in labels" :key="label.id" @click="getLabelMails(label.id, label.name, true)">
                <span class="inner__icon-wrapper" :style="labelColor(label.color)">
                  <SvgSprite class="inner__icon icon icon-color-white" symbol="label-mask" size="32"/>
                </span>
                <button class="inner__button" type="button">{{label.name}}</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="navigation__item">
          <div class="custom-progress">
            <div class="custom-progress__track">
              <span class="custom-progress__bar" :style="pb_width"></span></div>
            <div class="custom-progress__size">{{$t('used')}} {{size}} {{$t('of')}} {{limit}}</div>
          </div>
        </div>
      </div>
      <footer-lang></footer-lang>
    </div>
    <div class="aside-panel__backdrop" v-if="isHidedMenu"
      @click="hideMenu"
    ></div>
    <div class="aside-panel__container">
      <div class="aside-panel__current-item">
        <p class="aside-panel__menu-title">{{active_in_header.name}}</p>
        <p class="aside-panel__counter" v-if="+active_in_header.unread > 0">+{{active_in_header.unread}}</p>
      </div>
      <search-line
        v-if="isMobile && !mailIsOpen && this.subfolders && this.subfolders.length > 0"
        :lang="lang"
        :email="email"
        wrapper=".aside-panel__container"
        :activeEmail="activeEmail"
        :amount="amount"
        :subfolders="subfolders"
        :counter_click_folder="counter_click_folder"
        @active_search="active_search"
        @search_mail="search_mail"
        @refresh="refresh"></search-line>
      <!-- Меню пользователя -->
      <div class="user" @click="toggleMobileHeader">
        <div class="user__avatar-wrapper">
          <img :src="avatar_url" class="avatar_class" />
        </div>
      </div>
      <div class="user-menu" :class="{'user-menu--active': isOpenedMobileHeader}">
        <ul class="user-menu__list">
          <li class="user-menu__item">
            <div class="user">
              <div class="user__avatar-wrapper">
                <img :src="avatar_url" class="avatar_class" />
              </div>
              <a class="user__nickname" href="#">{{email_user}}</a>
              <a v-if="!userAlias" class="user__wmid" href="#" @click="copy_func_handler(wmid, 'wmid_'+wmid)">
                <span class="user__label">
                  WMID:
                </span>
                {{wmid}}
                <SvgSprite class="user__icon icon" symbol="copy" size="30"/>
                <span class="copied" v-if="copied['wmid_'+wmid]">{{ $t('copied') }}</span>
              </a>
            </div>
          </li>
          <li class="user-menu__item" @click="logout">
            <SvgSprite class="user-menu__icon icon" symbol="exit" size="30"/>
            {{ $t('logout') }}
          </li>
          <li class="user-menu__item" @click="loginAnotherWmid">
            <SvgSprite class="user-menu__icon icon" symbol="plus" size="30"/>
            {{ $t('login_another_wmid') }}
          </li>
        </ul>
      </div>
    </div>

    <button class="button button--primary button--message" type="button"  @click="create_mail()">
      <span class="button__text button__text--message">
        <SvgSprite class="icon" symbol="edit" size="30"/>
      </span>
    </button>
      <!-- Панель меню для таблет-версии -->
    <div class="aside-panel__wrapper">
      <vue-select :select_values="aliasesEmails"
        :_values="aliasesId"
        :actual_value="search_alias"
        :disabled="userAlias"
        @change_value="setNewSearchAlias">
      </vue-select>
      <ul class="aside-panel__list">
        <li class="aside-panel__item" v-for="fldr in subfolders?.filter(el => !el.isCollector)" :key="fldr.id"
            @click="setActive(fldr.name, fldr.id, getFolderName(fldr), fldr.unread, false)">
          <button class="aside-panel__button" :class="{'aside-panel__button--active': active===fldr.id}">
            <SvgSprite
              class="aside-panel__icon icon"
              :id="`folder_${fldr.id}`"
              :symbol="getFolderImg(fldr)"
              size="30"
              :style="active===fldr.id ? 'color: #0080D8;' : ''"
              @mouseover="showTT(fldr.id, getFolderName(fldr), 'folder_')" @mouseleave="upHere = false; tt_name=''; tt_id=0"
            />
          </button>
        </li>
        <li class="aside-panel__item" v-for="fldr in collectorFolders" :key="fldr.id"
            @click="setActive(fldr.name, fldr.id, fldr.name, fldr.unread, false)">
          <button class="aside-panel__button" :class="{'aside-panel__button--active': active===fldr.id}">
            <SvgSprite
              class="navigation__icon icon"
              symbol="archive-mail"
              size="30"
              :id="`folder_${fldr.id}`"
              :style="active===fldr.id ? 'color: #0080D8;' : ''"
              @mouseover="showTT(fldr.id, fldr.name, 'folder_')"
              @mouseleave="upHere = false; tt_name=''; tt_id=0"
            />
          </button>
        </li>
        <template v-if="!userAlias">
          <li class="aside-panel__item" v-for="label in labels" :key="label.id" @click="getLabelMails(label.id, label.name, false)">
            <button class="aside-panel__button">
              <SvgSprite class="aside-panel__icon icon icon-color-white" symbol="label-mask" size="30"
                :style="labelColor(label.color)"
                :id="`label_${10000+label.id}`"
                @mouseover="showTT(10000+label.id, label.name, 'label_')" @mouseleave="upHere = false; tt_name=''; tt_id=0"
              />
            </button>
          </li>
        </template>
      </ul>
    </div>
    <p class="custom-tooltip"  v-show="upHere"
      :style="`top:${tt_y-56}px`"> <span class="custom-tooltip__text">{{tt_name}}</span></p>
  </section>
</template>
<script>
import FolderContent from "./FolderContent.vue";
import FooterLang from '../ui/FooterLang.vue'
import {bytesToSize} from '../helpers/Size'
import SearchLine from '../header/SearchLine.vue'
import VueSelect from '../helpers/VueSelect.vue'
import {copy_func} from '@/components/helpers/copy_function'
export default {
  name: "LeftSidebar",
  components: {
    FolderContent,
    FooterLang,
    SearchLine,
    VueSelect
  },
  props: {
    email: String,
    amount: Number,
    folders: Array,
    active: Number,
    account: Object,
    subfolders: Array,
    activeEmail: Number,
    counter_click_folder: Number,
    lang: String,
    mailIsOpen: Boolean,
    isMobile: Boolean
  },
  emits: ['create_new_mail', 'set_active', 'add_label', 'open_folder', 'move_to', 'labels_loaded', 'refresh', 'get_label_mails', 'create_folder', 'search_mail'],
  data() {
    return {
      labels: [],
      flags: ['trash', "sent", "junk", "draft"],
      icons: ["trash", "send", "spam", "draft"],
      isCollapseNavigationList: true,
      isAsidePanelOffCanvas: true,
      isActiveSelectAliases: false,
      isFocusedSelectAliases: false,
      actualAlias: this.email,
      accountAliases: [],
      aliasesEmails: [],
      aliasesId: [],
      search_alias: '',
      isOpenedMobileHeader: false,
      active_in_header: {fullname: '', name: '', unread: 0},
      copied: {},
      wmid: '',
      email_user: '',
      login_another_wmid_url: '',
      avatar_url: '',
      isAsidePanelOpen: false,
      isButtonCollapse: false,
      upHere: false,
      tt_name: '',
      tt_id: 0,
      tt_y: 0,
      isHidedMenu: false,
      isActive: false,
      userAlias: ''
    };
  },
  computed: {
    pb_width(){
      if(this.account && this.account.quota && this.account.quota.limit && this.account.quota.size && this.account.quota.limit > 0)
        return "width: " + this.account.quota.size*100/this.account.quota.limit + "%"
      return "width: 0%"
    },
    limit(){
      if(this.account && this.account.quota && this.account.quota.limit)
        return bytesToSize(this.account.quota.limit*1024)
      return ''
    },
    size(){
      if(this.account?.quota && ('size' in this.account.quota))
        return bytesToSize(this.account.quota.size * 1024)
      return ''
    },
    inboxInboxSubfolders(){
      if(this.folders && this.folders[0].subfolders && this.folders[0].subfolders.length > 0){
        const i = this.folders[0].subfolders.findIndex(subf => subf.name === "INBOX.INBOX")
        if(i!==-1)
          return this.folders[0].subfolders[i].subfolders
      }
      return []
    },
    otherSubfolders(){
      if(this.folders && this.folders[0].subfolders && this.folders[0].subfolders.length > 0){
        const i = this.folders[0].subfolders.findIndex(subf => subf.name === "INBOX.INBOX")
        if(i!==-1){
          let fldrs = this.folders[0].subfolders.slice()
          fldrs.splice(i,1)
          return fldrs.filter(el => !el.isCollector)
        } else
          return this.folders[0].subfolders.filter(el => !el.isCollector)
      }
      return []
    },
    collectorFolders(){
      if(this.folders && this.folders[0] && this.folders[0].subfolders && this.folders[0].subfolders.length > 0){
        return this.folders[0].subfolders.filter(el => el.isCollector)
      }
      return []
    },
    foldersLoaded(){
      return this.folders.length > 0
    }
  },
  watch: {
    '$route.params.a_id'(val){
      this.setNewSearchAliasFromRoute(val)
    }
  },
  methods: {
    copy_func_handler(text, icon){
      copy_func(this.copied, text, icon)
    },
    active_search(isActive){
      this.isActive = isActive
    },
    refresh(){
      this.$emit('refresh')
    },
    search_mail(val, type){
      this.$emit('search_mail', val, type)
    },
    updateFolderUnseenInMobile(folder, unseen){
      if(folder.name === this.active_in_header.fullname)
        this.active_in_header.unread = unseen
    },
    showTT(id, name, str){
      if(id === this.tt_id) return
      this.upHere = true
      this.tt_id = id
      const span = document.getElementById(str+id)
      const main_page = document.getElementById('main_page')
      if(main_page && span){
        const bcr = span.getBoundingClientRect()
        if(bcr) this.tt_y = bcr.top+main_page.scrollTop
      }
      this.tt_name = name
    },
    loginAnotherWmid(){
      this.$router.push("/login?noredirect=true")
    },
    logout(){
      window.location.href = '/auth/wm/logout'
    },
    toggleMobileHeader(){
      if(this.$refs.aside_panel.classList.contains('show') && this.isAsidePanelOffCanvas)
        this.$refs.aside_panel_toggle_button.click()
      this.isOpenedMobileHeader = !this.isOpenedMobileHeader
    },
    add_label(e){
      e.stopPropagation()
      if(this.isAsidePanelOffCanvas)
        this.$refs.aside_panel_toggle_button.click()
      this.$emit('add_label')
    },
    createFolder(){
      if(this.isAsidePanelOffCanvas)
        this.$refs.aside_panel_toggle_button.click()
      this.$emit("create_folder")
    },
    getFolderImg(folder){
      if(folder.name === "INBOX")
        return 'message'
      const i = this.flags.findIndex(el => folder.system === el)
      if(i !== -1 && i < this.icons.length)
        return this.icons[i]
      return "folder"
    },
    getFolderName(folder){
      if(folder.name === "INBOX")
        return this.$t(folder.name)
      if(folder.system){
        const i = this.flags.findIndex(el => folder.system === el)
        if(i !== -1)
          return this.$t(this.flags[i])
      }
      if(folder.isCollector){
        return folder.name
      }
      const arr = folder.name.split('.')
      if(arr.length > 0)
        return arr[arr.length-1]
      return folder.name
    },
    hideMenu(){
      this.$refs.aside_panel_toggle_button.click()
      this.isHidedMenu = false
    },
    setActive(name, id, folder_name, unread, isMobile){
      if(this.isAsidePanelOffCanvas && isMobile)
        this.$refs.aside_panel_toggle_button.click()
      this.active_in_header.name = folder_name
      this.active_in_header.fullname = name
      this.active_in_header.unread = unread
      this.$router.push({name: 'folder', params: {a_id: this.$route.params.a_id || 'all', f_id: id, page: 1}})
    },
    labelColor(color){
      return { 'background-color': color }
    },
    create_mail() {
      this.$emit("create_new_mail");
    },
    async loadLabels(){
      this.isLoading = true
      try{
        await this.$store.dispatch('getLabels')
        this.labels = this.$store.getters['labels']
        this.$emit('labels_loaded', this.labels)
      }catch(err){
        this.error = err.message || 'smth went wrong'
        this.widthPB()
      }
      this.isLoading = false;
    },
    getLabelMails(label_id, label_name, isMobile){
      if(this.isAsidePanelOffCanvas && isMobile){
        this.$refs.aside_panel_toggle_button.click()
      }
      this.active_in_header.name = label_name
      this.active_in_header.fullname = ''
      this.active_in_header.unread = 0
      this.$router.push({name: 'label', params: {a_id: this.$route.params.a_id, l_id: label_id, page: 1}})
    },
    onDrop(evt, emailId) {
      const uid = evt.dataTransfer.getData('itemID')
      this.$emit("move_to", emailId, +uid);
      if (evt.target.classList.contains("dropzone1")) {
        evt.target.classList.remove("dragover");
      }
    },
    moveTo(emailId, uid){
      this.$emit("move_to", emailId, +uid);
    },
    setNewSearchAliasFromRoute(val){
      const i = this.aliasesId.findIndex(el=>el+''===val+'')
      if(i!==-1){
        this.search_alias = this.aliasesEmails[i]
        this.$store.dispatch("setLeftSidebarSelect", this.search_alias);
        if(this.$route.name !== 'mail') {
          this.$emit('refresh')
        }
      }
    },
    setNewSearchAlias(val){
      const i = this.aliasesId.findIndex(el=>el+''===val+'')
      if(i!==-1){
        // eslint-disable-next-line
        const {a_id, msg_id, params} = this.$route.params
        let name = this.$route.name
        if(msg_id){
          const route_name = this.$route.name
          name = 'folder'
          if(route_name === 'search_message')
            name = 'search'
          else if (route_name === 'label_message')
            name = 'label'
        }
        this.$router.push({name: name, params: {a_id: this.aliasesId[i], ...params}})
      }
    },
    myEventHandler(){
      const that = this
      if (window.matchMedia('(min-width: 768px)').matches) {
        if(that.isHidedMenu) that.hideMenu()
        that.isCollapseNavigationList = false
        that.isAsidePanelOffCanvas = true
      }
      if (window.matchMedia('(min-width: 1300px)').matches) {
        if(that.isHidedMenu) that.hideMenu()
        that.isAsidePanelOffCanvas = false
      }
      if (window.matchMedia('(max-width: 576px)').matches)
        that.isAsidePanelOffCanvas = true
      if (window.matchMedia('(max-width: 767px)').matches)
        that.isAsidePanelOffCanvas = true
    }
  },
  created(){
    this.loadLabels()
    let email = this.$store.getters['user'].Email
    this.userAlias = this.$store.getters['user'].Info?.alias
    if(email){
      this.wmid = email.substr(0,12)
      this.avatar_url = this.userAlias ? "/images/icons/contacts-fill.svg" : 'https://events.web.money/open/avatar.aspx?t=Url&s=normal&w='+this.wmid
      this.email_user = this.userAlias || email
    }
    if (window.matchMedia('(min-width: 768px)').matches) {
      this.isCollapseNavigationList = false
      this.isAsidePanelOffCanvas = true
    }
    if (window.matchMedia('(min-width: 1300px)').matches)
      this.isAsidePanelOffCanvas = false
    window.addEventListener("resize", this.myEventHandler);
    document.addEventListener("dragenter", (event) => {
      // highlight potential drop target when the draggable element enters it
      if (event.target.classList.contains("dropzone1"))
        event.target.classList.add("dragover");
    });
    document.addEventListener("dragleave", (event) => {
      // reset background of potential drop target when the draggable element leaves it
      if (event.target.classList.contains("dropzone1"))
        event.target.classList.remove("dragover");
    });
    window.addEventListener('click', (e) => {
      if(!e.target.closest('.user-menu') && this.isOpenedMobileHeader && !e.target.closest('.user'))
        this.isOpenedMobileHeader = false
    });
    if(this.account?.aliases && Array.isArray(this.account.aliases) && this.email || this.$store.getters["getAccount"] && this.$store.getters["getAccount"].aliases){
      let aliases = []
      let email = ''
      if(this.$store.getters["getAccount"].aliases && this.$store.getters["getAccount"].aliases.length >0)
        aliases = this.$store.getters["getAccount"].aliases
      else if(this.account.aliases && this.account.aliases.length > 0)
        aliases = this.account.aliases.slice()
      if(this.$store.getters['user'].Email)
        email = this.$store.getters['user'].Email
      else if(this.email)
        email = this.email
      let arr = []
      arr.push({id: 'all', email: this.$t('all_messages')})
      const i = aliases.findIndex(el => el.email === email)
      if(i===-1)
        arr.push({id: 0, email: email})
      arr = arr.concat(aliases)
      this.accountAliases = arr
      this.accountAliases.forEach(el => {
        this.aliasesEmails.push(el.email)
        this.aliasesId.push(el.id)
      })
      if(this.userAlias){
        this.search_alias = this.userAlias
        this.$store.dispatch['setLeftSidebarSelect', this.search_alias]
      }
      const selectEmail = this.$store.getters["getLeftSidebarSelect"]
      const {a_id, params} = this.$route.params
      if(a_id){
        const j = this.aliasesId.findIndex(el=>el+''===a_id)
        if(j!==-1){
          if(!this.userAlias) {
            this.search_alias = this.aliasesEmails[j]
            this.$store.dispatch['setLeftSidebarSelect', this.search_alias]
          } else if(this.userAlias !== this.aliasesEmails[j]){
            window.location.href = '/mail'
          }
        }
      }else if(selectEmail){
        this.search_alias = selectEmail
        const j = this.aliasesEmails.findIndex(el=>el===selectEmail)
        if(j!==-1)
          this.$router.push({name: this.$route.name, params: {a_id: this.aliasesId[j], ...params}})
      } else {
        this.search_alias = this.aliasesEmails[0]
        this.$router.push({name: this.$route.name, params: {a_id: this.aliasesId[0], ...params}})
        this.$store.dispatch['setLeftSidebarSelect', this.search_alias]
      }
    }
  },
  mounted(){
    const element = document.getElementById("webMoneyHeader");
    if(element){
      const div = element.children;
      if(div && div.length > 0){
        const divs = div[0].children;
        if(divs && divs.length > 3){
          const inner_div = divs[3].children;
          if(inner_div && inner_div.length > 0){
            const sub1_div = inner_div[0].children;
            if(sub1_div && sub1_div.length > 0){
              const links = sub1_div[0].children;
              if(links && links.length > 6 &&  links[6].href){
                setTimeout(()=>{
                  this.login_another_wmid_url = links[6]['href']
                }, 2000)
              }
            }
          }
        }
      }
      if(this.userAlias){
        const img = document.querySelector("[src='/images/icons/contacts-fill.svg']")
        if(img){
          img.title=this.userAlias
        }
      }
    }
  }
};
</script>
<style scoped>
@media(max-width: 768px) {
  .navigation__add {
    opacity: 1;
  } 
}

.avatar_class{
  -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    width: 32px;
    height: 32px;
    border-radius: 32px;
}
  .dropzone1.dragover {
    border: 1px dashed gray;
  }
  .user__nickname{
    max-width: 206px;
  }

.visibility_aside_panel{
  visibility: hidden;
}
.visibility_aside_panel_unset{
  visibility: unset !important;
}

.inner__list {
    padding: 0px 0 0px 32px !important;
}
.inner__item .inner__list {
    padding: 0 0 !important;
}

.inner__folder {
  transition: transform 0.15s, color 0.15s ease-in-out;
}

.inner__sub-item {
    padding: 0px 0px 0px 32px !important;
}

.aside-panel--collapsed .aside-panel__button--collapse .icon {
  transform: rotate(-180deg);
}

.aside-panel__button--hide > .icon {
  margin-left: auto;
}

.aside-panel__offcanvas .button__text .icon {
  margin-right: 4px;
}

.aside-panel--collapsed .aside-panel__offcanvas .button__text {
  width: auto;
}

.aside-panel--collapsed .aside-panel__offcanvas .icon {
  margin: 0 auto;
}

.aside-panel--collapsed .custom-select__selected .icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-select__selected .icon {
  right: 10px;
}

.inner__icon-wrapper {
  width: 30px;
  height: 30px;
}

.inner__item--label .inner__icon {
  width: 32px;
  height: 32px;
}

.inner__item--label .inner__icon,
.inner__icon-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.aside-panel .button--simple {
  text-align: left;
  justify-content: flex-start
}

.button--secondary .button__text .icon {
  position: absolute;
  font-size: 30px;
  line-height: 30px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

@media(min-width: 768px) {
  .aside-panel__list {
    margin: 20px 3px 0 0;
  }

  .button--secondary .button__text .icon {
    position: static;
    transform: none;
  }
}

.navigation,
.aside-panel__list,
.aside-panel__mailboxes {
  scrollbar-width: initial;
}

  .navigation::-webkit-scrollbar,
  .aside-panel__mailboxes::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  .navigation::-webkit-scrollbar, 
  .navigation::-webkit-scrollbar-track, 
  .navigation::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  .navigation::-webkit-scrollbar-thumb {
    background-color: #D2D6DB;
  }

  .aside-panel__mailboxes .aside-panel__icon {
    position: absolute;
    left: 0;
    top: 4px;
    width: 24px;
    height: 24px;
  }

  .aside-panel__mailboxes .aside-panel__item {
    position: relative;
    padding-left: 32px;
  }
</style>
