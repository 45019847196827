import { createRouter, createWebHistory } from "vue-router";
import MailPage from "../components/mails/MailPage";
import {getCookie, setCookie, inIframe} from '@/components/helpers/CookiesHelper';
import store from "@/store";

const LoginPage = () => import("../components/login/LoginPage");
const SettingsPage = () => import("../components/settings/SettingsPage");
const ContactsPage = () => import("../components/contacts/ContactsPage");
const DecryptPage = () => import ('../components/decrypt/DecryptPage')
const AboutPage = () => import ('../components/about/AboutPage')

const routes = [
  { path: "/", redirect: "/mail" },
  {
    name: "about",
    path: "/about",
    component: AboutPage
  },
  {
    name: 'mail',
    path: "/mail", 
    component: MailPage,
    children: [
      {
        name: 'folder', 
        path: 'folder/:a_id/:f_id/:page',
        props: true,
        component: () => import ('../components/mails/MailsList.vue'),
        children: [
          {
            name: 'message', 
            path: ':msg_id',
            props: true,
            component: () => import ('../components/mails/mail_item_details/MailItemDetails.vue'),
          }
        ]
      },
      {
        name: 'label', 
        path: 'label/:a_id/:l_id/:page',
        props: true,
        component: () => import ('../components/mails/MailsList.vue'),
        children: [
          {
            name: 'label_message', 
            path: ':msg_id',
            props: true,
            component: () => import ('../components/mails/mail_item_details/MailItemDetails.vue'),
          }
        ]
      },
      {
        name: 'search', 
        path: 'search/:a_id/:f_id/:l_id/:page/:search_text/:all_folder/:subject/:text/:sender/:from/:to',
        props: true,
        component: () => import ('../components/mails/MailsList.vue'),
        children: [
          {
            name: 'search_message', 
            path: ':msg_id',
            props: true,
            component: () => import ('../components/mails/mail_item_details/MailItemDetails.vue'),
          }
        ]
      }
    ]
  },
  { path: "/settings", 
    name: "settings",
    component: SettingsPage,
    redirect: "/settings/main",
    children: [
      { 
        name: 'main', 
        path: 'main', 
        component: () => import ('../components/settings/MainSection')
      },
      { 
        name: 'profiles', 
        path: 'profiles', 
        component: () => import ('../components/settings/profiles/ProfilesSection'),
        children: [
          {
            name: 'profile_create', 
            path: 'create', 
            component: () => import ('../components/settings/profiles/ProfilesForm'),
          },
          {
            name: 'profile_edit', 
            path: 'edit/:id', 
            component: () => import ('../components/settings/profiles/ProfilesForm'),
          }
        ]
      },
      { 
        name: 'ba', 
        path: 'ba', 
        component: () => import ('../components/settings/BaSection')
      },
      {
        name: 'collectors',
        path: 'collectors',
        component: () => import ('../components/settings/collector/CollectorsSection.vue'),
        children: [
          {
            name: "collector_create",
            path: 'create',
            component: () => import ('../components/settings/collector/CollectorCreation.vue'),
          },
          {
            name: "collector_confirm",
            path: 'confirm/:email',
            component: () => import ('../components/settings/collector/CollectorConfirmation.vue'),
          }
        ]
      },
      { 
        name: 'd_emails', 
        path: 'd_emails', 
        component: () => import ('../components/settings/DemailsSection')
      },
      { 
        name: 'domains', 
        path: 'domains', 
        component: () => import ('../components/settings/domains/DomainsSection'),
        redirect: '/settings/domains/list',
        children: [
          {
            name: 'domains_list', 
            path: 'list', 
            component: () => import ('../components/settings/domains/DomainsList'),
            children: [
              {
                name: 'domain_create', 
                path: 'create', 
                component: () => import ('../components/settings/domains/DomainsForm'),
              },
            ]
          },
          {
            name: 'domain_confirm', 
            path: 'confirm/:id', 
            component: () => import ('../components/settings/domains/ConfirmSection'),
          },
          {
            name: 'domain_dkim', 
            path: 'dkim/:id', 
            component: () => import ('../components/settings/domains/DkimSection'),
          },
          {
            name: 'domain_remove', 
            path: 'remove/:id', 
            component: () => import ('../components/settings/domains/RemovingDomain'),
            props: true
          },
          {
            name: 'domain_emails', 
            path: 'emails/:id', 
            component: () => import ('../components/settings/domains/EmailsSection'),
          },
        ]
      },
      { 
        name: 'access', 
        path: 'access', 
        component: () => import ('../components/settings/AccessSection')
      },
      { 
        name: 'folders', 
        path: 'folders', 
        component: () => import ('../components/settings/folders/FoldersSection'),
        children: [
          {
            name: 'folder_create', 
            path: 'create', 
            component: () => import ('../components/settings/folders/FoldersForm'),
          },
          {
            name: 'folder_edit', 
            path: 'edit/:id', 
            component: () => import ('../components/settings/folders/FoldersForm'),
          }
        ]
      },
      { 
        name: 'labels', 
        path: 'labels', 
        component: () => import ('../components/settings/labels/LabelsSection'),
        children: [
          {
            name: 'label_create', 
            path: 'create', 
            component: () => import ('../components/settings/labels/LabelsForm'),
          },
          {
            name: 'label_edit', 
            path: 'edit/:id', 
            component: () => import ('../components/settings/labels/LabelsForm'),
          }
        ]
      },
      { 
        name: 'filters', 
        path: 'filters', 
        component: () => import ('../components/settings/filters/FiltersSection'),
        children: [
          {
            name: 'filter_create', 
            path: 'create', 
            component: () => import ('../components/settings/filters/FiltersForm'),
          },
          {
            name: 'filter_edit', 
            path: 'edit/:id', 
            component: () => import ('../components/settings/filters/FiltersForm'),
          }
        ]
      },
      { 
        name: 'aliases', 
        path: 'aliases', 
        component: () => import ('../components/settings/aliases/AliasesSection'),
        children: [
          {
            name: 'alias_create', 
            path: 'create', 
            component: () => import ('../components/settings/aliases/AliasesForm'),
          }
        ]
      },
      {
        name: 'support',
        path: 'support',
        component: () => import ('../components/settings/support/SupportSection'),
      }
    ]
  },
  { path: "/login", component: LoginPage, },
  { path: "/decrypt", component: DecryptPage, },
  { path: "/contacts", name: "contacts", component: ContactsPage},
  { path: "/:notFound(.*)", redirect: "/mail" },
  { 
    path: "/about", component: AboutPage 
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async function (to, _, next) {
  if(to.matched && to.matched.length > 0 && (to.matched[0].name === 'settings' || to.matched[0].name === 'contacts')){
    const userAlias = store.getters['user'].Info?.alias
    if(userAlias){
      next('/mail')
    } else if(!store.getters['user'].wmid){
      try {
        await store.dispatch('loadUser')
        const userAlias = store.getters['user'].Info?.alias
        if(userAlias){
          next('/mail')
        }
      } catch(err){
        console.log(err)
      }
    }
  }
  const redirectToPath = getCookie('redirect')
  if(redirectToPath && _.fullPath === "/" && to.fullPath === '/mail' && !inIframe()){
    setCookie('redirect', "", {
      'max-age': -1
    })
    next(redirectToPath);
  } else {
    next();
  }
});

export default router;
