import {messages} from "@/locales";
import {getCookie} from "@/components/helpers/CookiesHelper";
const lang = getCookie('lang') || navigator.language || process.env.VUE_APP_I18N_LOCALE

export const validateEmail = (str) => {
      return String(str)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+[.]*(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}
export const validateNumber = (str) => {
  return String(str)
    .match(
      /^\d+$/
    );
}

export const errorMessage = (err) => {
  if(err?.message) {
    let msg = ''
    if(err && err.response && err.response.data && err.response.data.error
      && (err.response.data.error.message || err.response.data.error.meta)){
      if(err.response.data.error.meta && err.response.data.error.meta.code && err.response.data.error.meta.code === 500){
        msg = messages[lang]['internal_error']
        // t('internal_error')
      }else if(err.response.data.error.meta && err.response.data.error.meta.i8s && messages[lang][err.response.data.error.meta.i8s]){
        msg = messages[lang][err.response.data.error.meta.i8s]
      }
      else if(err.response.data.error.message)
        msg = err.response.data.error.message
    }else{
      msg = err.message || "smth went wrong";
    }
    return msg
  }
}

export const makeid = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const URL_add_parameter = (url, param, value) => {
  // console.log('url',url)
  var hash       = {};
  var parser     = document.createElement('a');
  parser.href    = url;
  var parameters = parser.search.split(/\?|&/);
  for(var i=0; i < parameters.length; i++) {
      if(!parameters[i])
          continue;
      var ary      = parameters[i].split('=');
      hash[ary[0]] = ary[1];
  }
  hash[param] = value;
  var list = [];  
  Object.keys(hash).forEach(function (key) {
      list.push(key + '=' + hash[key]);
  });
  parser.search = '?' + list.join('&');
  return parser.href;
}
export const fileExt = (ext) => {
    switch(ext){
        case 'pdf':
            return 'pdf'
        case 'doc':
        case 'docx':
        case 'txt':
        case 'odt':
        case 'tex':
        case 'rtf':
            return 'doc'
        case 'mp3':
        case 'wav':
        case 'flac':
        case 'aac':
        case 'aa':
            return 'audio'
        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
            return 'xls'
        case 'mkv':
        case 'flv':
        case 'avi':
        case 'mp4':
            return 'video'
        case 'zip':
        case 'zipx':
        case 'rar':
        case '7z':
        case 'tar':
            return 'archive'
        case 'jpg':
        case 'png':
        case 'apng':
        case 'avif':
        case 'gif':
        case 'jpeg':
        case 'jfif':
        case 'pjpeg':
        case 'pjp':
        case 'svg':
        case 'webp':
            return 'img'
        default:
            return 'other'
    }
}
