export const errors_ru = {
    "alias_max_count_paying": "У Вас есть неоплаченный псевдоним!",
    "error_alias_status_paying": "У вас есть неоплаченный счет!",
    "pay_invoice": "Оплатите счет в WebMoney Keeper!",
    "confirmation_error": "ошибка подтверждения",
    "pay_invoice_cancel": "Счет отклонен",
    "rejected": "cчет отклонен",
    "email_not_specified": "Не указан email для получателя.",
    "message_not_found": "Сообщение не найдено",
    "entity_not_found": "Объект не найден",
    "internal_imap_error": "Внутренняя ошибка imap",
    "err_provider_already_exists": "Провайдер уже существует",
    "err_invalid_id": "Неверный ID",
    "err_invalid_email": "Неверный email",
    "forbidden": "Запрещено",
    "invalid_auth_cookie": "Недействительные auth cookie",
    "expected_token": "Ожидаемый токен",
    "incorrect_login_or_password": "Неверный логин или пароль",
    "invalid_login": "Неверный логин",
    "invalid_wmid": "Неверный wmid",
    "mailbox_not_exist": "Email не существует",
    "duplicate_aliases": "Повторяющиеся псевдонимы",
    "max_count_aliases": "Максимальное количество псевдонимов",
    "user_not_found": "Пользователь не найден",
    "user_create_error": "Ошибка создания пользователя",
    "request_folder_id_error": "Ошибка запроса идентификатора папки",
    "request_folder_not_found": "Запрашиваемая папка не найдена",
    "folder_sent_not_found": "Папка Отправленные не найдена",
    "folder_draft_not_found": "Папка Черновики не найдена",
    "folder_trash_not_found": "Папка Корзина не найдена",
    "folder_already_exist": "Папка уже существует",
    "folder_parent_wrong": "Неправильная родительская папка",
    "request_profile_id_error": "Ошибка id профиля запроса",
    "profile_not_found": "Профиль не найден",
    "request_label_id_error": "Ошибка id ярлыка запроса",
    "specify_3_30": "Указывайте от 3 до 30 символов",
    "invalid_alias": "Указан некорректный псевдоним",
    "invalid_alias_wmid": "Псевдоним из 12 цифр создавать нельзя",
    "invalid_alias_purse": "Псевдоним из буквы и 12 цифр создавать нельзя",
    "invalid_email1": "Некорректно указан email!",
    "invalid_wmid2": "Указан некорректный WMID.",
    "internal_error": "Внутренняя ошибка",
    "folder_children_exist": "Папка содержит подпапки!",
    "alias_has_no_at": "Email должен содержать символ '@' и домен, например: example@wmid.com",
    "invalid_request_params": "Недопустимые параметры запроса",
    "alias_stoplist": "Нельзя создать с данным именем",
    "error_domain_status_paying": "У вас есть неоплаченный счет!",
    "filter_name_is_empty": "Укажите название фильтра!",
    "failed_validation_aliases_email": "Указан некорректный псевдоним!",
    "name_not_indicated": "Вы не указали имя!",
    failed_validation_email_address: "Некорректно указан email",
    "translate_fail": "Перевести сообщение не удалось",
    invalid_port: "Некорректно указан порт",
    request_code_not_found: "Вы указали неверный код подтверждения.",
    cannot_connect_to_mail_server: "Невозможно подключиться к почтовому серверу",
    collector_invalid_password: "Неверный пароль",
    cant_fetch_inbox: "Входящие не найдены",
    invalid_domain_for_mail_server: "Недопустимый домен для почтового сервера",
    mail_server_not_found: "Почтовый сервер не найден",
    collector_invalid_email: "Недействительный email коллектора",
    collector_not_created: "Коллектор не может быть создан",
    invalid_provider: "Некорректный провайдер",
    invalid_token: "Недействительный токен",
    collector_not_found: "Коллектор не найден",
    auth_error: "Ошибка авторизации",
    max_limit_reached: "Достигнут максимальный лимит: {limit}Мб",
    invalid_params: "Некорректные параметры",
    alias_not_found: "Псевдоним не найден",
    alias_cant_delete: "Псевдоним не может быть удален"
}
