<template>
  <div class="custom-select">
    <div 
        @focusout="handleFocusOut"
        tabindex="0">
        <div class="custom-select__selected"
          :class="{'custom-select__selected--active': active, 'cursor_class': disabled}"
          @click="clickOnSelectedValue">
            <span class="custom-select__text">{{actual_value}}</span>
            <SvgSprite class="icon" symbol="arrow-dropdown" size="30"/>
        </div>
        <div class="custom-select__menu"
            :class="{'custom-select__menu--hide': !active}">
            <div class="custom-select__item" 
            v-for="(val,i) in select_values" 
            :class="{'custom-select__item--selected': actual_value === val}"
            @click="selectNewValue(_values[i])" :key="val">{{val}}</div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VueSelect",
  emits:['change_value'],
  props: {
      select_values: Array,
      _values: Array,
      actual_value: String,
      disabled: Boolean
  },
  data() {
      return {
        active: false,
        // popper
      }
  },
  methods: {
        selectNewValue(val){
          //   this.$refs.refSelectLettersPerPage.selectedIndex = i
          this.$emit('change_value', val)
          this.active = !this.active
        },
        clickOnSelectedValue(e){
          if(this.disabled) {
            return
          }
          e.stopPropagation();
          this.active = !this.active
        },
        handleFocusOut(){
          this.active = false
        }
    },
    mounted(){
        // this.popper = Popper.createPopper(trigger, options, {
        // placement: 'bottom-start',
    // })
    }
}
</script>
<style scoped>
.cursor_class{
  cursor: default;
  color: gray;
}



@media(min-width: 768px){
  .aside-panel__wrapper .custom-select__selected {
    height: 48px;
  }
}

.custom-select__selected .icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(0);
  transition: 0.15s ease-in-out;
}

.custom-select__selected--active .icon {
  transform: translateY(-50%) rotate(-180deg);
}

.custom-select__menu {
 scrollbar-width: initial;
}

.custom-select__menu::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.custom-select__menu::-webkit-scrollbar, 
.custom-select__menu::-webkit-scrollbar-track, 
.custom-select__menu::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

.custom-select__menu::-webkit-scrollbar-thumb {
  background-color: #D2D6DB;
}

.custom-select__item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-mail .custom-select__selected .icon {
  color: #101828;
}
</style>
